<template>
  <Page full>
    <Grid
      type="client"
      title="Clients + leads"
      :multiple="true"
      :isMain="true"
      :showDefaultFilterPresets="true"
      :filters="{
        client_status: '!i&&!d&&!x'
      }"
      :filterText="{
        client_status: 'Active'
      }"
    />
  </Page>
</template>

<script>
import Grid from '../ui/Grid/Grid.vue'
import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],
  components: { Grid }
}
</script>
